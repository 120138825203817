svg {
  height: 565px;
  width: 700px;
  margin-left: 10px;
}


@keyframes circle {
  0% {
    transform:
      /* starting position offset */
      rotate(20deg)
      /* half circle size + .5px(for precision) */
      translate(-123.5px)
      /* how much the icon should rotate as it goes around */
      rotate(-40deg);
  }

  100% {
    transform:
      /* full circle rotation: 360 + 20 offset */
      rotate(380deg)
      /* half circle size + .5px(for precision) */
      translate(-123.5px)
      /* how much the icon should rotate as it goes around */
      rotate(-40deg);
  }
}

.truck01 {
  width: 90px;
  height: 90px;
  content: url("assets/img/truck01.svg");
  border-radius: 50%;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  -webkit-animation: circle 15s linear infinite;
  animation: circle 15s linear infinite;
}

@media only screen and (max-width:760px) {

  svg {
    height: 650px;
    width: 550px;
    margin-left: 45px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-120.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-120.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 90px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -15px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}

@media screen and (max-width:650px) {

  svg {
    height: 700px;
    width: 560px;
    margin-left: 40px;

  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-123.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-123.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 90px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -6px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}

@media screen and (max-width:600px) {

  svg {
    height: 700px;
    width: 550px;
    margin-left: 23px;

  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-118.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-118.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 90px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -6px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}




@media only screen and (max-width:550px) {

  svg {
    height: 600px;
    width: 500px;
    margin-left: 15px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-107.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-107.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 70px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -20px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}



@media only screen and (max-width:500px) {

  svg {
    height: 600px;
    width: 450px;
    margin-left: 10px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-97.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-97.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 70px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -30px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:470px) {

  svg {
    height: 600px;
    width: 440px;
    margin-left: 15px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-93.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-93.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 70px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -5px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:450px) {

  svg {
    height: 600px;
    width: 400px;
    margin-left: 20px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-85.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-85.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}

@media only screen and (max-width:440px) {

  svg {
    height: 600px;
    width: 400px;
    margin-left: 20px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-84.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-84.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: 5px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:410px) {

  svg {
    height: 430px;
    width: 380px;
    margin-left: 20px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-83.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-83.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: 10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}





@media only screen and (max-width:400px) {

  svg {
    height: 600px;
    width: 390px;
    margin-left: 0px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-85.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-85.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: -10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:380px) {

  svg {
    height: 400px;
    width: 370px;
    margin-left: 0px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-80.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-80.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 185px;
    bottom: 0;
    left: -10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:370px) {

  svg {
    height: 430px;
    width: 360px;
    margin-left: 0px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-78.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-78.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 185px;
    bottom: 0;
    left: -10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}

@media only screen and (max-width:360px) {

  svg {
    height: 400px;
    width: 330px;
    margin-left: 15px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-72.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-72.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 185px;
    bottom: 0;
    left: 0px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:350px) {

  svg {
    height: 400px;
    width: 330px;
    margin-left: 5px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-72.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-72.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 50px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 185px;
    bottom: 0;
    left: -10px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:320px) {

  svg {
    height: 370px;
    width: 300px;
    margin-left: 8px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-64.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-64.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 40px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 190px;
    bottom: 0;
    left: -5px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}


@media only screen and (max-width:300px) {

  svg {
    height: 330px;
    width: 250px;
    margin-left: 20px;
  }



  @keyframes circle {
    0% {
      transform:
        /* starting position offset */
        rotate(20deg)
        /* half circle size + .5px(for precision) */
        translate(-54.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }

    100% {
      transform:
        /* full circle rotation: 360 + 20 offset */
        rotate(380deg)
        /* half circle size + .5px(for precision) */
        translate(-54.5px)
        /* how much the icon should rotate as it goes around */
        rotate(-40deg);
    }
  }

  .truck01 {
    width: 90px;
    height: 40px;
    content: url("assets/img/truck01.svg");
    border-radius: 50%;
    position: absolute;
    top: 195px;
    bottom: 0;
    left: -5px;
    right: 0;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 15s linear infinite;
    animation: circle 15s linear infinite;
  }
}



/* Extra Section  */

.vl {
  border-right: 3px solid green;
  height: 30px;
  margin-right: 40rem;



}

.v2 {
  margin-left: -15px;
}

.error {
  color: crimson;
}


/* css */
/* tab section */
.container1 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, minmax(50px, auto));
  grid-template-rows: repeat(5, minmax(0px, auto));
}




[class^="item"] {
  text-align: center;
  box-sizing: border-box;

  transition: .0s;
}

[class^="item"]:hover {
  z-index: 2;
  /*we increase the z-index to cover the other*/
  /*background:
    /*we this to keep the initial background
    linear-gradient(rgba(255, 255, 255), rgba(255, 255, 255)),
    #fff; */
}

[class^="item"]:nth-child(1):hover {
  margin-right: calc(-0% - 0px);
  /* 1st slide */
  display: inline-block;


}

[class^="item"]:nth-child(4):hover {
  margin-left: calc(-0% - 0px);
  /* 4slide */
  margin-right: calc(-0% - 0px);
  display: inline-block;
}

[class^="item"]:nth-child(5):hover {
  margin-left: calc(-0% - 0px);
  /* 5th slide */
  margin-right: calc(-0% - 0px);
  display: inline-block;
}

[class^="item"]:nth-child(3):hover {
  margin-right: calc(-0% - 0px);
  /* 3nd child*/
  margin-left: calc(-0% - 0px);
  display: inline-block;
}

[class^="item"]:nth-child(2):hover {
  margin-right: calc(-0% - 0px);
  /* 2nd child*/
  margin-left: calc(-0% - 0px);
  display: inline-block;
}




/* End */




.container1>div {
  border-radius: 10px;
}

@media only screen and (min-width: 789px) {
  .container1 {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .container1 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1120px) {
  .container1 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 789px) {
  .container1 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 640px) {
  .container1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

#sidebar {
  display: none;
}

.cart:hover #sidebar {
  display: block;
}

.logo-container {}

.logo-container:hover>#logo {
  display: none;
}

.logo-container:hover>#logo-easter-egg {
  display: inline-block;
}

#logo-easter-egg {
  display: none;
}

/* mobile view tab */

.logo-container1 {
  height: 320px;
}

.logo-container1:hover>#logo {
  display: none;
}

.logo-container1:hover>#logo-easter-egg {
  display: inline-block;
}

/* End */

.iconnss {
  border-radius: 1.5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -0px 0px 4px rgba(0, 0, 0, 0.25);
}

.iconnss1 {
  border-radius: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(56, 152, 236, 0);
  box-shadow: 0 3px 6px -1px #888787, 0 -3px 5px 1px #fff;
  height: 75px;
  width: 75px;

}

.iconnss1:hover {
  border-radius: 10px;
  box-shadow: inset 0 -1px 5px 1px #fff, inset 0 3px 8px -3px #838383;
  font-family: Poppins, sans-serif;
  color: #313131;
}

.iconnss2 {
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 30px;
  height: 30px;

}


.heigt {
  line-height: 1.8rem;
}



.neu {

  background: #dde1e7;
  border-radius: 40%;
  box-shadow: inset -3px -3px 5px #ffffff70, inset 3px 3px 5px #00000070;
}

.test1 {
  width: 50%;
  margin: 5rem auto;
  height: 570px;

}

.slide img {
  width: 20rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.55;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: aqua;
}


.next {
  right: 0;
  top: 25%;
}

.prev {
  left: 0;
  top: 20%;
}



/* Media query*/

@media (min-width: 1536px) {}

@media (min-width: 1280px) {}

@media (min-width: 1024px) {}

@media (min-width: 768px) {}

@media (min-width: 640px) {}

@media (min-width: 600px) {}

@media (min-width: 400px) {}

@media (min-width: 350px) {}

.testimonial-reel {
  margin-bottom: 4rem !important;
  margin-top: 4rem;
}

.testimonial-reel .box {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-reel .box .image {
  margin: 0 auto -45px;
  text-align: center;
}

.testimonial-reel .box .image img {
  height: 130px;
  margin: 0 auto;
}

.testimonial-reel .box .test-component {
  background-color: #fff;
  padding: 1rem 2.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  min-height: 200px;
}

.testimonial-reel .box .test-title {
  font-family: "Open Sans", sans-serif;
  color: #f96d02;
  text-align: center;
  margin-top: 3.3rem;
  margin-bottom: 15px;
}

.testimonial-reel .box .test-content {
  text-align: center;
}

.testimonial-reel .slick-slide {
  opacity: 0.3;
  transition: opacity 0.3s;

  display: flex;
  flex-wrap: nowwrap;
}

.testimonial-reel .slick-slide.slick-cloned {
  opacity: 0.3;
  transition: opacity 0.3s;
}

.testimonial-reel .slick-slide.slick-current.slick-active {
  opacity: 1;
  transition: opacity 0.3s;
}



.let {
  font-family: "Manrope", sans-serif;
}

.tabsection {
  border-radius: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(56, 152, 236, 0);
  box-shadow: 0 3px 6px -1px #888787, 0 -3px 5px 1px #fff;
}

.tabsection1:hover {
  border-radius: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(56, 152, 236, 0);
  box-shadow: 0 3px 6px -1px #888787, 0 -3px 5px 1px #fff;
}

.innersection {
  border-radius: 6px;
  background: #efebeb2e;
  box-shadow: inset 0 -4px 5px 1px #1d181829, inset 0 3px 8px -3px #838383c4;

}

.pointer {
  cursor: pointer;
}

.testi {
  width: 350px;
  margin: auto;
  height: 50px;
}


.contactsample {
  border-radius: 10px;
  box-shadow: inset 0 -1px 10px 1px #fff, inset 0 1px 3px 0 #838383;
}






/* cursor css 


.content p {
  width: 700px;
  line-height: 26px;
  font-size: 18px;
  text-align: justify;
}

.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 3px solid #7ed956;
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: .1s;
}

.cursor2 {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: #7ed956;
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: .15s;
}

.content:hover~.cursor {
  transform: translate(-50%, -50%) scale(1.5);
  background-color: #c2eed9;
  opacity: .5;
}

.content:hover~.cursor2 {
  opacity: 0;
}
*/


/* Map */

.responsive-map {
  overflow: hidden;
  padding: 30% 20% 0% 20%;
  position: relative;
  height: 0;
}

.responsive-map iframe {
  left: 0;
  top: 0;
  height: 400px;
  width: 100%;
  position: absolute;
}

/* Sidebar */

/*
 *  STYLE 5
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #7ed956;

  background-image: -webkit-gradient(linear, 0 0, 0 100%,
      color-stop(.5, rgba(255, 255, 255, .2)),
      color-stop(.5, transparent), to(transparent));
}

/*END*/

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #7ed956;
}

::selection {
  color: #fff;
  background: #7ed956;
}


/* Accordion */




@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --white-400: hsl(0, 0%, 100%);
  --black-400: hsl(0, 0%, 0%);
  --gray-200: hsl(0, 0%, 10%);
  --gray-400: hsl(0, 0%, 20%);
  --gray-600: hsl(0, 0%, 80%);
  --page_bg-clr: var(--black-400);
  --page_clr: var(--white-400);
  --accordion__button_bg-clr: var(--gray-400);
  --accordion__button_clr: var(--gray-600);
  --accordion__text_bg-clr: var(--gray-200);
  --accordion__text_clr: var(--gray-600);
  --icon_bg-clr: var(--gray-600);
  --primary-ff-fallback: sans-serif;
  --primary-ff: "Poppins", var(--primary-ff-fallback);
  --fs-500: 1.1rem;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page {
  display: flex;

  color: var(--page_clr);
  font-family: var(--primary-ff);
  align-items: center;
  justify-content: center;

}

.accordion {
  width: clamp(20em, 50vw, 30em);
}

.accordion__button {
  opacity: 0.9;
  width: 100%;
  color: rgb(8, 8, 8);
  border: none;
  display: flex;
  padding: 0.7em 1em;
  cursor: pointer;
  justify-content: space-between;
  font-weight: 700;
  font-size: var(--fs-500);
}

.accordion__button:hover,
.accordion__button:focus {
  opacity: 1;
  border: #1b4cd3;
}

.accordion__button:focus .icon::before {
  display: none;
}

.accordion__heading {
  font-size: 1.1rem;
}

.accordion__text {
  color: rgb(49, 153, 50);
  padding: 0.5em 2em;
  font-size: 0.95rem;
  line-height: 1.65;

}

.icon {
  position: relative;
  display: grid;
  width: 10px;
  place-items: center;
  aspect-ratio: 1;
  color: #313131;
}

.icon::before,
.icon::after {
  background: var(--icon_bg-clr);
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
}

.icon::before {
  rotate: 90deg;
}

.accordion__button+.accordion__text {
  display: block;
}

.accordion__button:focus+.accordion__text {
  display: block;
}



.fnt {
  font-family: DynaPuff;
}


.het {
  height: 230px;
}



/* desktop dropdown */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}



/* mobile sidemenu */


.checkbox-container {
  display: block;
  float: right;
  margin-top: -10px;
  margin-right: -25px;
  height: 10px;
  position: relative;
  background: #182825;
}

.checkbox-trigger {
  opacity: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  cursor: pointer;
  z-index: 5;
}

.hamburger-menu,
.hamburger-menu::before,
.hamburger-menu::after {
  display: block;
  position: absolute;
  background: rgb(126, 217, 86);
  width: 25px;
  height: 4px;
  margin: 1.3em 3em;
  transition: background 0.3s;
}

.hamburger-menu::before,
.hamburger-menu::after {
  content: '';
  position: absolute;
  margin: -0.7em 0 0;
  transition: width 0.7s ease 0.3s, transform 0.7s ease 0.3s;
}

.hamburger-menu::after {
  margin-top: 0.7em;
}

.hamburger-menu {
  position: relative;
  display: block;
  margin: 0;
  margin-top: 1.45em;
  margin-right: 1.35em;
  margin-left: 0.35em;
  margin-bottom: 1.45em;
  transition: width 0.3s ease;
}

.checkbox-trigger:checked {
  left: 307px;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu {
  width: 2em;

  transition: width 0.7s ease 0.7s;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu::before {
  width: 1.2em;
  transform: rotate(-35deg);
  margin-top: -0.4em;
}

.checkbox-trigger:checked+.menu-content .hamburger-menu::after {
  width: 1.2em;
  transform: rotate(35deg);
  margin-top: 0.4em;
}

.checkbox-trigger:checked+.menu-content ul {
  width: 305px;
  height: 800px;
  transition: width 0.7s ease 0.3s, height 0.3s ease;
}

.menu-content {
  display: flex;
  background: #fff;

  float: left;
}

.menu-content ul {
  display: block;
  padding-left: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s ease 0.7s, width 0.7s ease;
}

.menu-content ul li {
  list-style: none;
  cursor: pointer;
  transition: color 0.5s, background 0.5s;
  padding-top: 15px;
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* mobile  dropdwns*/

.dropdown {
  position: relative;
  display: inline-block;
}

.drop-content,
.drop-content2 {
  display: none;
  position: absolute;
  background-color: #1b4cd3;
  min-width: 120px;
  font-size: 16px;
  top: 34px;
  z-index: 1;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.4);
}

/* on hover show dropdown */
.dropdown:hover .drop-content,
.dropdown2:hover .drop-content2 {
  display: block;
}

/* drondown links */
.drop-content a {
  padding: 12px 10px;
  border-bottom: 1px solid rgb(197, 197, 250);
  display: block;
  transition: all 0.5s ease !important;
}

.dropBtn .drop-content a:hover {
  background-color: rgb(230, 230, 230);
}

.dropdown:hover .dropBtn,
.dropdown2:hover .dropBtn2 {
  background-color: rgba(0, 0, 0, 0.3);
}

.dropdown2 .drop-content2 {
  position: absolute;
  left: 120px;
  top: 126px;
}

.dropBtn2 i {
  margin-left: 15px;
}





.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url('https://source.unsplash.com/nKC772R_qog');
  background-size: cover;
  background-position: center;
  position: relative;
}

.avatar::before,
.avatar::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #333;

  position: absolute;
  top: -.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.avatar::before {
  --translate-y: calc(-100% - var(--arrow-size));

  content: attr(data-tooltip);
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: max-content;
  max-width: 100%;
  background: var(--tooltip-color);
}

.avatar:hover::before,
.avatar:hover::after {
  --scale: 1;
}

.avatar::after {
  --translate-y: calc(-1 * var(--arrow-size));

  content: '';
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}







.slideshow {
  list-style-type: none;
}



.slideshow,
.slideshow:after {
  top: -16px;

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 0;
}

.slideshow li span {
  position: absolute;
  width: 100%;
  height: 150%;
  top: 70px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 30s linear infinite 0s;
}

.slideshow li:nth-child(1) span {
  background-image: url("assets/img/vegebackground.png");
}

.slideshow li:nth-child(2) span {
  background-image: url("assets/img/herbalbackground.png");
  animation-delay: 6s;
}

.slideshow li:nth-child(3) span {
  background-image: url("assets/img/fruitsbackground.png");
  animation-delay: 12s;
}

.slideshow li:nth-child(4) span {
  background-image: url("assets/img/vegebackground.png");
  animation-delay: 18s;
}

.slideshow li:nth-child(5) span {
  background-image: url("assets/img/herbalbackground.png");
  animation-delay: 24s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  17% {
    opacity: 1
  }

  25% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0
  }

  8% {
    opacity: 1
  }

  17% {
    opacity: 1
  }

  19% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}



.ex {
  z-index: -10000;
}


.rightend {
  justify-content: end;
}



#team {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 3;
}

.card {
  width: 13%;
  min-width: 50px;
  height: 195px;
  overflow: hidden;
  position: relative;
  margin: 0 0.5% 0.4% 0;
  display: inline-block
}

.card:nth-of-type(3n) {
  margin-right: 0;
}

.card img {
  width: 100%;
  min-height: 100%;
  transition: all 0.9s
}

.card:hover img {
  transform: scale(1.2, 1.2)
}

.data {
  position: absolute;
  background: rgb(186 229 191 / 89%);
  text-align: center;
  width: 90%;
  height: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 5%;
  box-sizing: border-box;
  opacity: 0.8;
  transition: all 0.4s
}

.card:hover .data {
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.9;
  padding: 30px 10%
}

.data h2 {
  margin: 0 0 5px 0;
  transition: color 0.4s
}

.data p {
  opacity: 0;
  text-align: left;
  transition: all 0.2s
}

.card:hover .data p,
.card:hover .data a {
  opacity: 1
}

.data a {
  color: #333;
  text-decoration: none;
  padding: 20px;
  opacity: 0
}

.data a:hover,
.card:hover h2 {
  color: #38c
}

@media (max-width:799px) {
  .card {
    width: 50%;
    margin-right: 0;
    margin-bottom: 5px;
  }

}



/* follow me @nodws */
.btn-twtr {
  color: #ddd;
  border: 2px solid;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: 100px;
  opacity: 0.8
}










.ourTeam {
  background: #fff;
  height: 200px;
}

.ourTeam h2 {
  color: #222;
  margin: 0 0 30px 0
}

.ourTeam .i {
  margin-top: 30px
}

.ourTeam .i .c {
  background: #fff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  overflow: hidden;
  padding-bottom: 110px
}

.ourTeam .i .c .wrap {
  position: relative
}

.ourTeam .i .c .wrap {
  width: 20%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.ourTeam .i .c .wrap .info {
  padding: 30px 0;
  position: absolute;
  top: 100%;
  width: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.ourTeam .i .c .wrap .info .name {
  margin: 0;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  margin: 0 0 8px 0
}

.ourTeam .i .c .wrap .info .position {
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
  color: #555659
}

.ourTeam .i .c .more {
  position: absolute;
  bottom: -80%;
  width: 20%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.ourTeam .i .c .more p {
  margin: 0 18px 30px 18px;
  line-height: 22px
}

.ourTeam .i .c .more .socials {
  margin: 0 0 20px 0
}

.ourTeam .i .c .more .socials a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  font-size: 2.2rem;
  color: #fff;
  margin: 0 0 0 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: center;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 3px 0 rgba(0, 0, 0, .1)
}

.ourTeam .i .c .more .socials a:first-child {
  margin: 0
}

.ourTeam .i .c .more .socials a.facebook {
  background: #3262b9
}

.ourTeam .i .c .more .socials a.facebook:hover {
  background: #2d57a5
}

.ourTeam .i .c .more .socials a.twitter {
  background: #3dd7e5
}

.ourTeam .i .c .more .socials a.twitter:hover {
  background: #27d2e2
}

.ourTeam .i .c .more .socials a.google-plus {
  background: #e23535
}

.ourTeam .i .c .more .socials a.google-plus:hover {
  background: #de2020
}

.ourTeam .i .c .more .socials a.linkedin {
  background: #069
}

.ourTeam .i .c .more .socials a.linkedin:hover {
  background: #005580
}

.ourTeam .i .c:hover img {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0
}

.ourTeam .i .c:hover .info {
  top: 0
}

.ourTeam .i .c:hover .more {
  bottom: 0
}

/*Functional Styling*/
@media (pointer: coarse),
(hover: none) {
  [title] {
    position: relative;
    display: flex;
    justify-content: center;
  }

  [title]:focus::after {
    content: attr(title);
    position: absolute;
    top: 90%;
    color: #000;
    background-color: #fff;
    border: 1px solid;
    width: fit-content;
    padding: 3px;
  }

}



.tabsection {
  border-radius: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(56, 152, 236, 0);
  box-shadow: 0 3px 6px -1px #888787, 0 -3px 5px 1px #fff;
}

.carrer {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  margin-top: 150px;
}

.resume {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 40px;
}

.form {
  padding: 30px;
}

.contactsample {
  border-radius: 8px;
  box-shadow: inset 0 -1px 10px 1px #fff, inset 0 1px 3px 0 #838383;
}

.submit {
  text-align-last: right;
  padding-top: 10px;
}

.small {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
  border: transparent;
  font-size: 18px;
  color: green;
}


.bgred {
  background-color: #71df3e;
}



.rad {
  border-radius: 40px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  background-color: rgba(126, 197, 113, 0);
  box-shadow: 0 3px 6px -1px #8cdb91, 0 -3px 5px 1px rgb(249, 250, 249);
}


.slick-next {
  background-color: #fff !important;
  color: #000 !important;
  border: #000;
}

.slick-prev {
  background-color: #fff !important;
  color: #000 !important;
  border: #000;
}

.slick-prev:before,
.slick-next:before {
  color: green !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 8px;
  background-color: #ffffff; /* Background color of the dropdown */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2)  ;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #232323; /* Initial text color (text-blueGray-500) */
  padding: 12px 16px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  display: block;
  background-color: transparent; /* Ensure the background remains transparent */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Change the color of dropdown links on hover */
.dropdown-content a:hover {
  color: #3e8e41; /* Hover text color (text-blueGray-700) */
  background-color: transparent; /* Ensure the background remains transparent */
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

